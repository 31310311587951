import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import '@/helpers/metrics/yandex'

const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

store.dispatch('application/init')

Vue.config.productionTip = false

import StepTemplate from '@/templates/step/Step'
import CommonTemplate from '@/templates/common/Common'

Vue.component('CommonTemplate', CommonTemplate)
Vue.component('StepTemplate', StepTemplate)


import Button from '@/components/gui/button/Button'
import Field from '@/components/form/field/Field'

Vue.component('Button', Button)
Vue.component('Field', Field)

Vue.mixin({
    computed: {
        $DICTIONARY() {
            return this.$store.state.dictionary.dictionary
        }
    }
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
